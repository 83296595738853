import React from "react";
import axios from "axios";

import { Divider, LinearProgress, Typography } from "@mui/material";
import { formatDate, numberOfDaysBetween } from "../../utils";
import MainCard from "./MainCard";
import Timeslot from "./Timeslot";

export default function BookingType({ type, date, bookings, onBook, onUnbook, club}) {
    const [isLoading, setLoading] = React.useState(true);
    const [timeSlots, setTimeSlots] = React.useState([]);
    const [error, setError] = React.useState("");

    const isOpen = () => {
        // if now is not more than advancebookingdays then closed
       const daysToGo = numberOfDaysBetween(new Date(), date);
       return daysToGo >= type.advancebookingdays;
        
    }

    React.useEffect(() => {
        const loadTimeSlots = () => {
            setLoading(true);
            var bodyFormData = new FormData();
            bodyFormData.append('month', date.getMonth()+1);
            bodyFormData.append('year', date.getFullYear());
            bodyFormData.append('day', date.getDate());
            bodyFormData.append('all', 'true');
            
            console.log("Loading timeslots for " + type.bookingname);
            axios(
                { 
                    url: "/apio/bookingtype/"+type.id+"/timeslots",
                    method: 'post',
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data" }
                }
            ).then(response => {
                setTimeSlots(response.data.map(t => { return { ...t, type }}));
                setLoading(false);
            }).catch(err => {
                setError(err);
                setLoading(false);
            })
        }
        loadTimeSlots();
    }, [date, type]);
    return (
        <MainCard title={`${type.bookingname} - ${formatDate(date)}`}>
            {/* <Typography variant="h6" color="textSecondary">{type.bookingname}</Typography> */}
            <Divider />
            {isLoading && <LinearProgress color="warning"/>}
            {/* <DayHeader title={title} date={date} start={start} stop={stop}></DayHeader> */}
            {!isLoading && timeSlots &&  <>
                {timeSlots.map((ts, index) => (
                    <Timeslot 
                        timeslot={ts} 
                        key={index} 
                        open={isOpen()} 
                        bookings={bookings}
                        onBook={onBook}
                        onUnbook={onUnbook}
                        currency={club.currency}
                    />
                ))}
            </>}
        </MainCard>
    )
}