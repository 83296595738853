import { useMemo } from 'react';

import { Typography, createTheme} from '@mui/material';
import { red } from '@mui/material/colors';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { alpha } from '@mui/material/styles';

const CustomShadows = (theme) => ({
  button: `0 2px #0000000b`,
  text: `0 -1px 0 rgb(0 0 0 / 12%)`,
  z1: `0px 2px 8px ${alpha(theme.palette.grey[900], 0.15)}`
  // only available in paid version
});


// const themeTypography = Typography(`'Public Sans', sans-serif`);
// const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#61dafb',
      light: '#61dafb',
      dark: '#21a1c4',
    },
    secondary: {
      main: '#b5ecfb',
      light: '#61dafb',
      dark: '#21a1c4',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: 'rgb(250, 250, 251)',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        padding: '20px 10px',
        margin: '10px',
        // backgroundColor: '#fff', // 5d737e
        backgroundColor: "rgb(255, 255, 255)",
        color: "rgb(38, 38, 38)",
        overflow: "hidden",
        border: "1px solid",
        borderRadius: "8px",
        boxShadow: "none"
      },
    },
    MuiButton: {
      root: {
        margin: '5px',
      },
    }   
  },
});
export default theme;