import { useRef, useState, Fragment } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Button,
  Stack,
  ListItemButton,
} from '@mui/material';

// project import
import MainCard from './MainCard';
import Transitions from './Transitions';
import { padStart } from 'lodash';

// assets
// import { BellOutlined, CloseOutlined, GiftOutlined, MessageOutlined, SettingOutlined } from '@ant-design/icons';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DeleteIcon from '@mui/icons-material/Delete';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Bookings = ({ club, bookings, onDelete }) => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const deleteBooking = (booking) => {
    onDelete(booking);
  }

  const iconBackColorOpen = 'grey.300';
  const iconBackColor = 'grey.100';

  if (!bookings || bookings.length === 0) {
    return <></>
  }
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75, mr: 2 }}>
      <Stack direction="row"><Typography color="textSecondary" sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mr: 1
      }}>Bookings</Typography> <IconButton
        disableRipple
        color="secondary"
        sx={{ color: 'text.error', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
          <Badge badgeContent={bookings.length} color="warning">
            <EventAvailableIcon />
          </Badge>
        </IconButton></Stack>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Selected Time Slots"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <IconButton size="small" onClick={handleToggle}>
                      <EventAvailableIcon />
                    </IconButton>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    {bookings.map((b, index) => (
                      <Fragment key={index}>
                        <ListItemButton>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter'
                              }}
                            >
                              <EventAvailableIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography variant="h6">
                                {b.type.bookingname}
                              </Typography>
                            }
                            secondary={`${padStart(b.day, 2, '0')}/${padStart(b.month, 2, '0')}/${b.year} ${b.from} - ${b.to} (${club.currency}${b.fee})`}
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="caption" noWrap>
                              <IconButton color='error' variant="outlined" size="small" onClick={() => deleteBooking(b)}><DeleteIcon fontSize='small'/></IconButton>
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                        </Fragment>

                    ))}

                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Bookings;
