export function formatDate(date) {
    return date ? date.toLocaleDateString("en-UK") : "";
}

export function numberOfDaysBetween(fromDate, toDate) {
    const from = new Date(fromDate);
    const to = new Date(toDate);
    const timeinmilisec = to.getTime() - from.getTime();
    return ( Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24)) );
}

