import React from "react";
import axios from "axios";
import { Alert, Grid, Typography } from "@mui/material";
import BookingType from "./elements/BookingType";
import PageHeader from "./elements/PageHeader";
import DateSelector from "./elements/DateSelector";
import useLocalStorage from "../utils/useLocalStorage";


/**
 * - Current date, back and forward
 * - 1 or more BookingTypes
 * - CurrentBooking Details
 * - Checkout Button
 * @param {*} param0 
 */
export default function Calendar(props) {
    const [currentDate, setCurrentDate] = React.useState(new Date());
    const [isLoading, setLoading] = React.useState(true);
    const [bookingTypes, setBookingTypes] = React.useState([]);
    const [error, setError] = React.useState("");
    const [club, setClub] = React.useState();
    // const [bookings, setBookings] = React.useState([]);
    const [bookings, setBookings] = useLocalStorage('bookings', []);


    // load orgid 
    React.useEffect(() => {
        const loadDay = () => {
            // console.log(window.location);
            const query = new URLSearchParams(window.location.search);
            const clubid = query.get('id');
            axios.defaults.headers.common['cid'] = query.get('id');
            axios.defaults.headers.common['withCredentials'] = true;

            if(!clubid) {
                return setError("No club id specified");
            }
            axios.get("/apio/clubs/" + clubid).then(response => {
                // console.log("Fetched club details");
                setClub(response.data);
                
                axios.get("/apio/bookingtype?clubid=" + query.get('id'), { 'headers': { 'cid': query.get('id') } }).then(response => {
                    // console.log("Fetched booking type details");
                    setBookingTypes(response.data.rows.filter(b => b.bookingtypestatus === "0"));
                    setLoading(false);
                }).catch(err => {
                    setError(err);
                    setLoading(false);
            })
            }).catch(err => {
                setError(err);
                setLoading(false);
            })

        }
        setLoading(true);
        loadDay()
    }, [props.location]);

    const onDeleteItem = (item) => {
        const index = bookings.findIndex(i => i.name === item.name && i.timeslot === item.timeslot);
        const items = JSON.parse(JSON.stringify(bookings));
        items.splice(index, 1);
        setBookings(items);
    }

    const book = (timeslot) => {
        setBookings([...bookings, timeslot]);
    }
    
    const unbook = (timeslot) => {
        // const current = [ ...bookings];
        console.log(timeslot);
        console.log(bookings);
        const filtered = bookings.filter(b => {
            if(b.type.id === timeslot.type.id && b.index === timeslot.index) {
                return false;
            }
            return true;
        });
        setBookings(filtered);
    }
    
    const getCol = () => {
        if(bookingTypes.length > 2) {
            return 4;
        }
        if(bookingTypes.length === 2) {
            return 6;
        }
        return 12;
    }

    return (
        <>
            <PageHeader title={club ? club.clubname : ' "Loading...'} items={bookings} onDelete={onDeleteItem} club={club}/>
            <Grid container spacing={1} sx={{mt:6 }}>
                <Grid item xs={12} sx={{mt:1}} >
                    {error && <Alert severity="error">{error}</Alert>}
                    {!error && <Typography variant="h6" color="textSecondary">Select date below and then select your preferred time slot(s) if available! Then click checkout button above to proceed. Scroll/ Drag right to see and select additional future dates...</Typography>}
                </Grid>
                <Grid item xs={12} sx={{mt:1}} >
                {!error && <DateSelector maxDays={14} selected={currentDate} onSelect={(date) => setCurrentDate(date)}/>}
                </Grid>
                {bookingTypes.map((bt, index) => (
                    <Grid key={index} item xs={12} sm={getCol()}>
                        <BookingType club={club} type={bt} date={currentDate} bookings={bookings} onBook={book} onUnbook={unbook} />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}