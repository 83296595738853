// import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

import App from './Components/App/App';
import theme from './theme';
import { createRoot } from 'react-dom/client';
import ThemeCustomization from './themes';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render( 
  <ThemeCustomization>
      <BrowserRouter>
        <CssBaseline />
        <App tab="home" />
      </BrowserRouter>
  </ThemeCustomization>
);