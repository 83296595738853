import React from "react";
import { useNavigate } from "react-router";import AppBarStyled from "./AppBarStyled";
import { Button, Stack, Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import Bookings from "./Bookings";
import axios from "axios";

/**
 * Name of Organisation, 
 * Logo, 
 * Go To Checkout, 
 * List of current bookings, 
 * Back to entries
 * 
 * @param {*} param0 
 * @returns 
 */

export default function PageHeader({ title, items, onDelete, club }) {
    const theme = useTheme();
    const navigate = useNavigate();

    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            p: 1,
            // boxShadow: theme.customShadows.z1
        }
    };

    const disabled = !items || items.length === 0;

    const addToCart = () => {
        const formData = new FormData();
        items.forEach((i, index) => {
            i['bookedplaces'] = 1;
            for (const property in i) {
                formData.append(`bookings[${index}][${property}]`, i[property]);
                // redirect to cart
                // formData.append(`bookings[${index}]["bookedplaces"]`, 1);
            }
        })
        

        formData.append('contactname', 'Michael Adams');
        formData.append('contactmobile', '0872363332');
        formData.append('email', 'Michael.Adams@eskersoft.com');
        formData.append('extravalue', 'Extra Value');
        formData.append('discountcode', '1234');
        formData.append('chosenpaymenttype', '1');
        formData.append('paymenttype', '1');
        formData.append('type', 'bookingtype');
        
        axios({
            method: "post",
            url: "/../org/"+club.shortcode+"/shoppingcart?command=add",
            data: formData,
            // headers: { "Content-Type": "multipart/form-data" },
          });
            // .then(function (response) {
            //   //handle success
            //   console.log(response);
            //     // window.location.href= "/localhost:8888/ips4/org/"+club.shortcode+"/shoppingcart";
            // })
            // .catch(function (response) {
            //   //handle error
            //   console.log(response);
            // });

    }

    const getPostUrl = () => {
        if(club) {
            return axios.defaults.baseURL + "/../org/"+club.shortcode+"/shoppingcart?command=add";
        }
        return "";
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBarStyled {...appBar}>
                <Stack direction="row">
                    <Box
                        component="img"
                        sx={{
                            height: 32,
                            width: 32,
                            pr: 1
                        }}
                        alt="IPS"
                        src="/ips-logo-sails-text-transparent-128.png"
                    />
                    <Typography
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        >
                        {title}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                        <Bookings club={club} bookings={items} onDelete={onDelete}/>
                        <form action={getPostUrl()} method="POST">
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            startIcon={<ShoppingCartCheckoutIcon style={{ color: disabled ? 'lightgrey' : 'orange' }} size="small" />}
                            // onClick={addToCart}
                            disabled={disabled}
                            type="submit"
                        >
                            Add To Cart
                        </Button>
                        <input type="hidden" name="type" value="bookingtype"  />
                        <input type="hidden" name="contactname" value="Michael Adams"  />
                        <input type="hidden" name="email" value="Michael.Adams@eskersoft.com"  />
                        <input type="hidden" name="extravalue" value="extra"  />
                        <input type="hidden" name="discountcode" value="1234"  />
                        <input type="hidden" name="chosenpaymenttype" value="1"  />
                        <input type="hidden" name="paymenttype" value="1"  />
                        {items.map((i, index) => {
                            i['bookedplaces'] = 1;
                            let inputs = [];
                            for (const property in i) {
                                inputs.push (<input type="hidden" name={`bookings[${index}][${property}]`} value={i[property]}  />);
                                // formData.append(`bookings[${index}][${property}]`, i[property]);
                                // redirect to cart
                                // formData.append(`bookings[${index}]["bookedplaces"]`, 1);
                            }
                            return inputs;
                        })}
                        </form>
                </Stack>
            </AppBarStyled>
        </Box>
    )
}

