import React from "react";
import { Chip, Button, Stack, Typography, Box } from "@mui/material";
import MainCard from "./MainCard";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';

/**
 * day: "18"
enforceminimumbookings: "0"
fee: "10"
from: "09:00"
index: "1-18-3-2024-1261"
minimumbookings: "1",
numbooked: 0
places: 1
placeslabel: "Places"

- handle stuff already in cart
- handle stuff alerady selected
- book button
- select num places where applicable
 */
export default function Timeslot({ timeslot, open, bookings, onBook, onUnbook, currency }) {

    const inCart = () => bookings.find(ts => ts.index === timeslot.index && ts.type.id === timeslot.type.id);

    const isBooked = () => {
        // check if already booked
        return timeslot.places === 0 || inCart();
    }

    const getColor = () => {
        if (!open) {
            return "default";
        }

        if (inCart()) {
            return "warning";
        }

        if (isBooked()) {
            return "error";
        }

        return "success";
    }

    const getLabel = () => {
        if (!open) {
            return "Closed";
        }

        if (inCart()) {
            return "Booking";
        }

        if (isBooked()) {
            return "Booked";
        }

        return "Free";
    }


    return (
        <MainCard sx={{ my: 0.5, borderRadius:0 }}>
            <Stack direction="row">
                <Chip sx={{borderRadius: 1}} size="small" color={getColor()} label={getLabel()} />
                <Typography sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ml: 1
                }}>{timeslot.from} - {timeslot.to}</Typography>
                <Box sx={{ flexGrow: 1 }} />
                {open && !isBooked() && <Button 
                    variant="outlined"
                    color="secondary"
                    startIcon={<BookmarkAddIcon color="success" size="small" />}
                    onClick={() => onBook(timeslot)}
                    size="small">
                        {`${currency}${timeslot.fee}`} Book 
                    </Button>}
                {open && inCart() && <Button 
                    variant="outlined"
                    color="secondary"
                    startIcon={<BookmarkRemoveIcon color="warning" size="small" />}
                    onClick={() => onUnbook(timeslot)}
                    size="small">
                        Un-Book
                    </Button>}

            </Stack>
        </MainCard>
    )
}