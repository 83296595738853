import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './ScrollBox.css';
import { Button } from '@mui/material';
import useScrollBox from '../hooks/useScrollBox';
import MainCard from './MainCard';
import { formatDate } from '../../utils';

function DateSelector({ maxDays, selected, onSelect }) {
    const scrollWrapperRef = useRef();
    const { isDragging } = useScrollBox(scrollWrapperRef);

    console.log("Selected: " + formatDate(selected));

    const same = (d1, d2) => formatDate(d1) === formatDate(d2);

    const getButton = (date) => (
        <Button
            variant={same(date, selected) ? "contained" : "outlined"}
            color={same(date, selected) ? "warning" : "secondary"}
            size="small"
            sx={{ mx: 1, fontWeight: 'bold' }}
            // startIcon={<ShoppingCartCheckoutIcon style={{ color: disabled ? 'lightgrey' : 'orange' }} size="small" />}
            onClick={() => onSelect(new Date(date))}
        >
            {formatDate(date)}
        </Button>
    )

    const getItems = () => {
        const items = [];
        const currentDate = new Date();
        for (let i = 0; i < maxDays; i += 1) {
            const d = new Date(currentDate);
            items.push(getButton(d));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return items;
    }

    return (
        // <MainCard>
        <div className="scroll-box">
            <div className="scroll-box__wrapper" ref={scrollWrapperRef}>
                <div className="scroll-box__container" role="list" style={{ pointerEvents: isDragging ? 'none' : undefined }}>
                    {getItems().map((child, i) => (
                        <div role="listitem" key={`scroll-box-item-${i}`} className="scroll-box__item">
                            {child}
                        </div>
                    ))}
                </div>
            </div>
        </div>
        // </MainCard>
    );
}

export default DateSelector;