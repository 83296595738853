import './App.css';
import {  Container } from '@mui/material';
import axios from 'axios';
import Calendar from '../Calendar';

switch(process.env.REACT_APP_NODE_ENV) {
  case 'production':
    axios.defaults.baseURL = 'https://itsplainsailing.com/admin';
    break;
  case 'staging':
    axios.defaults.baseURL = 'https://entries.club/admin';
    break;
  default:
    // axios.defaults.baseURL = 'http://localhost:8888/ips4/admin';
    break;
}

function App() {

  return (
    <Container maxWidth={false} className="App">
        <Calendar />
    </Container>
  );
}
export default App;